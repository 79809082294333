import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Collage, CollageType } from "../components/collages/Collage";

const InkPage = () => {
  return (
      <Layout>
        <SEO title="Ink" />
        <Collage type={CollageType.Ink} />
      </Layout>
  );
};

export default InkPage;
